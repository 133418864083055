<template>
  <MPopover position="bottom" is-hover>
    <template #activator>
      <div class="header-nav-profile">
        <NavItem
          :to="profileLink"
          :label="profileName"
          icon="i-user-header"
          no-prefetch
        />
      </div>
    </template>
    <template #content>
      <div class="profile-popover" :class="{ verified: isVerified }">
        <div v-if="!isVerified" class="profile-banner">
          <AImage
            class="profile-banner__image"
            src="/assets/images/banner-user.svg"
            width="232"
            height="112"
            from-public
          />
          <p class="profile-banner__text">
            {{ $t("molecules.profile-popup-menu.banner_text") }}
          </p>
          <AButton variant="grey" size="xs" @click.prevent="navigatePush('login')">
            <span class="profile-banner__button">
              {{ $t("molecules.header.sign-in") }}
            </span>
          </AButton>
        </div>
        <div
          v-else-if="customer"
          @click.prevent="navigatePush('/private_office/profile/')"
        >
          <AAvatar :name="customer.firstName" size="lg" />
          <div>
            <ServerRender
              is="Heading"
              level="3"
              size="xl"
            >
              {{ customer.firstName }}{{ getNameInitial(customer.lastName) }}
            </ServerRender>
            <div class="profile-popover__phone">
              {{ customer.phone }}
            </div>

            <LazyMBonus
              v-if="customer.bonusesAmount"
              variant="background"
              size="sm"
              type="user"
              :amount="customer.bonusesAmount"
            />
          </div>
        </div>

        <ul class="profile-popover__list">
          <MListItem
            v-for="item in menu"
            :key="item.localKey"
            :icon-name="item.icon"
            icon-size="base"
            @click.prevent="blankPush(item.url, $event)"
          >
            {{ $t(`molecules.profile-popup-menu.${item.localKey}`) }}
          </MListItem>
        </ul>

        <AActionButton v-if="isVerified" color="grey" underline @click="signOutAndUpdate">
          {{ $t("molecules.header.sign-out") }}
        </AActionButton>
      </div>
    </template>
  </MPopover>
</template>

<script setup lang="ts">
import { useLocalization } from '#imports'
import { computed, defineAsyncComponent } from 'vue'

import { useCustomerStore } from '@/stores/customer'
import { useFavoritesStore } from '@/stores/favorites'
import { useCartStore } from '@/stores/cart'
// import { useConfig } from '@/stores/config'

import NavItem from './HeaderNavItem.vue'
import AImage from '@/components/atoms/Image/AImage.vue'
import AActionButton from '@/components/atoms/ActionButton/AActionButton.vue'
import AAvatar from '@/components/atoms/Avatar/AAvatar.vue'
import ServerRender from '@/components/helpers/ServerRender'
import AButton from '@/components/atoms/Button/AButton.vue'
import MListItem from '@/components/molecules/ListItem/MListItem.vue'
import MPopover from '@/components/molecules/Popover/MPopover.vue'
import { useNavigate } from '@/composables/useNavigate'
import type { IconName } from '@/utils/icon-types'
import { useRoute } from 'vue-router'

const LazyMBonus = defineAsyncComponent(
  () => /* @vite-ignore */ import('@/components/molecules/Bonus/MBonus.vue')
)

type PopoverMenuItem = {
  localKey: string
  icon: IconName
  url: string
}

const { t } = useLocalization()
// const { isMscRegion } = useConfig()
const { getCart } = useCartStore()
const { signOut, isVerified, customer } = useCustomerStore()
const { getUrl, navigatePush, blankPush } = useNavigate()
const { getWishListProductIds, getWishListProducts } = useFavoritesStore()
const route = useRoute()

const menu = computed<PopoverMenuItem[]>(() => {
  const items = [
    { localKey: 'profile', icon: 'user-settings' as IconName, url: 'private_office' },
    { localKey: 'orders', icon: 'box' as IconName, url: 'private_office/orders' },
    { localKey: 'purchased-goods', icon: 'glass-wine' as IconName, url: 'private_office/purchases' }
  ]

  // if (isMscRegion.value) {
  //   items.push(
  //     {
  //       localKey: 'bonuses',
  //       icon: 'ws-bonus' as IconName,
  //       url: 'private_office/bonuses'
  //     },
  //     {
  //       localKey: 'companies',
  //       icon: 'office-bag' as IconName,
  //       url: 'private_office/my-company'
  //     }
  //   )
  // }

  return items
})
const profileName = computed(() => {
  const isAuth = isVerified.value && customer.value

  if (import.meta.server || !isAuth) {
    return t('molecules.header.sign-in')
  }

  return customer.value?.firstName
})
const profileLink = computed(() => {
  return isVerified.value ? getUrl('private_office') : getUrl('login')
})

const signOutAndUpdate = async () => {
  await signOut()

  await Promise.all([
    route.name === 'private_office-wishlist.php'
      ? getWishListProducts()
      : getWishListProductIds(),
    getCart()
  ])
}

const getNameInitial = (name: string) => name ? ` ${name[0]}.` : ''
</script>

<style lang="postcss">
.header-nav-profile {
  position: relative;

  &.is-show::after {
    content: "";
    position: absolute;
    top: 100%;
    right: -100%;
    left: -100%;
    display: block;
    height: 40px;
  }
}

.profile-widget {
  position: relative;
}

.profile-popover .profile-popover__list .list-item {
  padding: var(--spacer-3xs) var(--spacer-xs) var(--spacer-3xs) var(--spacer-3xs);
}

.profile-popover {
  width: calc(264px - var(--spacer-xs));
  margin: calc(var(--spacer-3xs) * -1) 0;
  user-select: none;

  &__phone {
    color: var(--color-neutral-600);

    @mixin text-sm;
  }

  &.verified {
    & > div:first-child {
      position: relative;
      display: flex;
      gap: var(--spacer-3xs);
      margin-bottom: var(--spacer-base);
      padding: var(--spacer-3xs) var(--spacer-3xs) var(--spacer-2xs) var(--spacer-3xs);
      border-bottom: 1px solid var(--color-neutral-300);
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }

    & > .action-btn {
      padding: var(--spacer-3xs) var(--spacer-3xs);
    }
  }
}

.profile-banner {
  position: relative;
  overflow: hidden;
  min-height: 112px;
  margin-bottom: var(--spacer-base);
  padding: var(--spacer-2xs) var(--spacer-xl) var(--spacer-2xs) var(--spacer-2xs);
  border-radius: var(--border-radius-xs);
  background: var(--gradient-red);

  & > *:not(.image) {
    position: relative;
    z-index: 1;
  }

  &__text {
    margin-bottom: var(--spacer-xs);
    color: var(--color-white);

    @mixin text-sm-bold;
  }

  &__button {
    padding: 0 var(--spacer-xs);
  }

  &__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    max-width: 232px;
  }
}

.profile-popover__list {
  & > .list-item {
    &::before {
      margin-right: var(--spacer-2xs);
    }

    .list-item__title {
      @mixin text-base;
    }
  }
}
</style>
